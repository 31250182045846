<template>
  <div class="form">
    <el-form :inline="true">
      <el-form-item>
        <el-date-picker
          class="time"
          v-model="date"
          type="daterange"
          range-separator="至"
          start-placeholder="开始日期"
          end-placeholder="结束日期"
          value-format="yyyy-MM-dd"
        ></el-date-picker>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="searchList">搜索</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        date: [],
      };
    },
    methods: {
      searchList() {
        let [startTime = '', endTime = ''] = this.date || [];
        // let param = Object.assign({ startTime, endTime }, this.search);
        this.$emit('search',{ startTime, endTime });
      }
    }
  };
</script>

<style lang="scss" scoped>
  .form {
    text-align: left;
  }
  @media screen and (max-width: 480px) {
    .form .time{
      width: 100%;
    }
  }
</style>
<style>
  @media screen and (max-width: 480px) {

    .el-date-range-picker{
      width: 100%!important;
      left: 0!important;
    }
    .el-date-range-picker .el-picker-panel__body{
      width: 100%;
      min-width: 100%!important;
    }
    .el-date-range-picker__content .el-date-range-picker__header div{
      text-align: center;
      width: 100%;
      margin-left: 0!important;
      margin-right: 0!important;
    }
  }
</style>
